import React, { useLayoutEffect, useRef } from "react";
import * as ClientDetailsStyles from "./clientDetails.module.scss";

import ClientDetailsImg from "../../../../assets/images/client-details-cover.png";
import ClientDetailsImg1 from "../../../../assets/images/client-details-1.png";
import ClientDetailsImg2 from "../../../../assets/images/client-details-2.png";
import Avatar from "../../../../assets/images/avatar.png";
import ClientStoriesShortListing from "../ClientStoriesShortListing/ClientStoriesShortListing";

import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

const ClientStoriesData = [
  {
    title: "A new life for IT Flakes",
    subtitle: "Business Continuity",
    link: "/client/it-flakes",
  },
  {
    title: "Unlocking pure agility for Smartboard",
    subtitle: "Performance Optimization And More ",
    link: "/client/smart-board",
  },
];

const DOK32Details = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("client-details-global-wrapper", 1.2);
  }, []);

  return (
    <section
      className={`${ClientDetailsStyles.ClientDetailsWrapper} client-details-global-wrapper`}
    >
      <span className="es-subtitle-text">Brand Transformation</span>
      <h1>Challenging the impossible for DOK32</h1>

      <div className={ClientDetailsStyles.clientDetailsCoverImg}>
        <img src={ClientDetailsImg} alt="A new life for IT Flakes" />
      </div>

      <div
        className={`${ClientDetailsStyles.ClientDetailsStory} story-text-wrapper`}
      >
        <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
          <span className="story-text">The story</span>
          <p>
            After 5 years of effort UAE’s DOK32 had given up on building a
            website that could reflect their product’s true performance. The
            company hired various designers, developers and other firms in order
            to build a website but nothing worked. When the firm joined Endshift
            its website was merely at the template stage. The founders had very
            low hopes of getting a quality website.
          </p>
        </div>
      </div>

      <div className={ClientDetailsStyles.ClientDetailsAdditional}>
        <div className={ClientDetailsStyles.ClientDetailsGallery}>
          <div>
            <img src={ClientDetailsImg1} alt="" />
          </div>
          <div>
            <img src={ClientDetailsImg2} alt="" />
          </div>
        </div>

        <div className={ClientDetailsStyles.ClientDetailsAdditionalDesc}>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>How did we do it?</span>
              <p>
                We put together a dedicated team for the project. We extracted
                the client’s vision, transformed it into end-to-end design and
                functionality in the form of the website. Our team moved between
                3 designers until the client was satisfied above and beyond
                their expectations.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>The difference between us and the rest:</span>
              <p>
                The website that stands today matches the quality of its
                product. Ultimately, the client was given more than what they
                thought was possible. This was a brand transformation journey
                only possible at endshift. We compromised on revenue and added
                extra efforts to deliver top-notch results.
              </p>
            </div>
          </div>
          <div className={ClientDetailsStyles.ClientDetailsStory}>
            <div className={ClientDetailsStyles.ClientDetailsStoryContent}>
              <span>Witness yourself here.</span>
              <div class={ClientDetailsStyles.ClientDetailsStoryLinkWrapper}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 0H0V24H24V0Z"
                    fill="white"
                    fillOpacity="0.01"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
                    stroke="#0066CC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M2 12H22"
                    stroke="#0066CC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22C14.2092 22 16 17.5229 16 12C16 6.47715 14.2092 2 12 2C9.79085 2 8 6.47715 8 12C8 17.5229 9.79085 22 12 22Z"
                    stroke="#0066CC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M4.92969 5.07129C6.73933 6.88094 9.23933 8.00024 12.0007 8.00024C14.7622 8.00024 17.2622 6.88094 19.0718 5.07129"
                    stroke="#0066CC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M19.0718 18.9289C17.2622 17.1193 14.7622 16 12.0007 16C9.23933 16 6.73933 17.1193 4.92969 18.9289"
                    stroke="#0066CC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <a href="https://www.dok32.com/" target="_blank">
                  DOK32.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ClientDetailsStyles.ClientDetailsNext}>
        <span className="es-subtitle-text">Next Projects</span>
        <ClientStoriesShortListing ClientStoriesData={ClientStoriesData} />
      </div>
    </section>
  );
};

export default DOK32Details;
