import React from "react";
import { Link } from "gatsby";
import * as ClientStoriesListingStyles from "./ClientStoriesShortListing.module.scss";

import { Arrow } from "../../../SVGs/SVGs";

const ClientStoriesShortListing = ({ ClientStoriesData }) => {
  return (
    <section
      className={`es-section__light ${ClientStoriesListingStyles.ClientStoriesListingWrapper}`}
    >
      <div className={ClientStoriesListingStyles.ClientStoriesListingGrid}>
        {ClientStoriesData.map((story, index) => (
          <div
            key={index}
            className={`${ClientStoriesListingStyles.ClientStoriesListingGridItem}`}
          >
            <Link to={story.link}>
              <div>
                <span className="es-subtitle-text CSItem">
                  {story.subtitle}
                </span>
                <h3 className="CSItem">{story.title}</h3>
              </div>
              <span className="CSItem">
                <Arrow color="var(--charcoal)" />
              </span>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ClientStoriesShortListing;
