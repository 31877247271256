import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import DOK32Details from "../../components/sections/ClientStoriesDetailsSection/ClientDetails/DOK32Details";
import ContactUsSection from "../../components/ContactUsSection/ContactUsSection";

const data = {
  title: "Got a query no one else is answering?",
  desc: "Time to bring it to our attention.",
};

const DOK32 = () => {
  return (
    <>
      <Helmet>
        <title>Challenging the impossible for DOK32</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-dark" />
      <main className="light-bg">
        <DOK32Details />
        <ContactUsSection data={data} />
      </main>
      <Footer />
    </>
  );
};

export default DOK32;
