// extracted by mini-css-extract-plugin
export var ClientDetailsWrapper = "clientDetails-module--ClientDetailsWrapper--GKtKu";
export var clientDetailsCoverImg = "clientDetails-module--clientDetailsCoverImg--eGH-o";
export var ClientDetailsStory = "clientDetails-module--ClientDetailsStory--umRpp";
export var ClientDetailsStoryContent = "clientDetails-module--ClientDetailsStoryContent--n0EeH";
export var ClientDetailsStoryLinkWrapper = "clientDetails-module--ClientDetailsStoryLinkWrapper---Bl02";
export var ClientDetailsAdditional = "clientDetails-module--ClientDetailsAdditional--OHcTx";
export var ClientDetailsGallery = "clientDetails-module--ClientDetailsGallery--1INbj";
export var ClientDetailsReview = "clientDetails-module--ClientDetailsReview--vif72";
export var ClientDetailsReviewContent = "clientDetails-module--ClientDetailsReviewContent--sTEQ4";
export var ClientDetailsReviewer = "clientDetails-module--ClientDetailsReviewer---0Asq";